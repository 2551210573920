export var localApplicationResources = {
    "followersCount": 15000,
    "followersCountFormatted": "15K",
    "contentCache": [
        {
            "id": 4,
            "title": "Property advice",
            "fullUrl": "/property-advice",
            "contentType": 0,
            "menuId": 2,
            "relativeIconUrl": "contentimages/svg/ec6a798c-03c7-4480-bc97-2d05bc282b6b.svg",
            "isHidden": false,
            "ancestors": []
        },
        {
            "id": 5,
            "title": "Buying guides",
            "fullUrl": "/property-advice/buying",
            "contentType": 0,
            "menuId": 0,
            "relativeIconUrl": "contentimages/svg/72bea796-acac-4211-8069-5f9f851c83b8.svg",
            "isHidden": false,
            "ancestors": [
                {
                    "id": 4,
                    "title": "Property advice"
                }
            ]
        },
        {
            "id": 1,
            "title": null,
            "fullUrl": "/property-advice/buying/how-to-buy-your-first-home",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 4,
                    "title": "Property advice"
                },
                {
                    "id": 5,
                    "title": "Buying guides"
                }
            ]
        },
        {
            "id": 4,
            "title": null,
            "fullUrl": "/property-advice/buying/sorting-out-your-finances-before-buying-a-property",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 4,
                    "title": "Property advice"
                },
                {
                    "id": 5,
                    "title": "Buying guides"
                }
            ]
        },
        {
            "id": 3,
            "title": null,
            "fullUrl": "/property-advice/buying/advantage-of-owning-your-own-home",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 4,
                    "title": "Property advice"
                },
                {
                    "id": 5,
                    "title": "Buying guides"
                }
            ]
        },
        {
            "id": 2,
            "title": null,
            "fullUrl": "/property-advice/buying/making-an-offer-on-a-new-home",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 4,
                    "title": "Property advice"
                },
                {
                    "id": 5,
                    "title": "Buying guides"
                }
            ]
        },
        {
            "id": 5,
            "title": null,
            "fullUrl": "/property-advice/buying/how-to-apply-for-a-mortgage",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 4,
                    "title": "Property advice"
                },
                {
                    "id": 5,
                    "title": "Buying guides"
                }
            ]
        },
        {
            "id": 6,
            "title": null,
            "fullUrl": "/property-advice/buying/a-quick-guide-to-conveyancing",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 4,
                    "title": "Property advice"
                },
                {
                    "id": 5,
                    "title": "Buying guides"
                }
            ]
        },
        {
            "id": 7,
            "title": null,
            "fullUrl": "/property-advice/buying/freehold-vs-leasehold",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 4,
                    "title": "Property advice"
                },
                {
                    "id": 5,
                    "title": "Buying guides"
                }
            ]
        },
        {
            "id": 8,
            "title": null,
            "fullUrl": "/property-advice/buying/exchange-and-completion",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 4,
                    "title": "Property advice"
                },
                {
                    "id": 5,
                    "title": "Buying guides"
                }
            ]
        },
        {
            "id": 9,
            "title": null,
            "fullUrl": "/property-advice/buying/how-to-prepare-for-moving-home",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 4,
                    "title": "Property advice"
                },
                {
                    "id": 5,
                    "title": "Buying guides"
                }
            ]
        },
        {
            "id": 16,
            "title": null,
            "fullUrl": "/property-advice/buying/help-to-buy-scheme",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 4,
                    "title": "Property advice"
                },
                {
                    "id": 5,
                    "title": "Buying guides"
                }
            ]
        },
        {
            "id": 17,
            "title": null,
            "fullUrl": "/property-advice/buying/shared-ownership",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 4,
                    "title": "Property advice"
                },
                {
                    "id": 5,
                    "title": "Buying guides"
                }
            ]
        },
        {
            "id": 18,
            "title": null,
            "fullUrl": "/property-advice/buying/jargon-buster-buying-a-property",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 4,
                    "title": "Property advice"
                },
                {
                    "id": 5,
                    "title": "Buying guides"
                }
            ]
        },
        {
            "id": 7,
            "title": "Renting guides",
            "fullUrl": "/property-advice/renting-guides",
            "contentType": 0,
            "menuId": 0,
            "relativeIconUrl": "contentimages/svg/ee1a16d9-ab79-4cf7-8958-991ddca6fc5b.svg",
            "isHidden": false,
            "ancestors": [
                {
                    "id": 4,
                    "title": "Property advice"
                }
            ]
        },
        {
            "id": 10,
            "title": null,
            "fullUrl": "/property-advice/renting-guides/how-to-rent-a-home",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 4,
                    "title": "Property advice"
                },
                {
                    "id": 7,
                    "title": "Renting guides"
                }
            ]
        },
        {
            "id": 11,
            "title": null,
            "fullUrl": "/property-advice/renting-guides/tenancy-agreements",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 4,
                    "title": "Property advice"
                },
                {
                    "id": 7,
                    "title": "Renting guides"
                }
            ]
        },
        {
            "id": 12,
            "title": null,
            "fullUrl": "/property-advice/renting-guides/rental-deposits",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 4,
                    "title": "Property advice"
                },
                {
                    "id": 7,
                    "title": "Renting guides"
                }
            ]
        },
        {
            "id": 19,
            "title": null,
            "fullUrl": "/property-advice/renting-guides/right-to-rent",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 4,
                    "title": "Property advice"
                },
                {
                    "id": 7,
                    "title": "Renting guides"
                }
            ]
        },
        {
            "id": 13,
            "title": null,
            "fullUrl": "/property-advice/renting-guides/moving-in-tips",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 4,
                    "title": "Property advice"
                },
                {
                    "id": 7,
                    "title": "Renting guides"
                }
            ]
        },
        {
            "id": 14,
            "title": null,
            "fullUrl": "/property-advice/renting-guides/reporting-problems-and-repairs",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 4,
                    "title": "Property advice"
                },
                {
                    "id": 7,
                    "title": "Renting guides"
                }
            ]
        },
        {
            "id": 15,
            "title": null,
            "fullUrl": "/property-advice/renting-guides/ending-your-tenancy-agreement",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 4,
                    "title": "Property advice"
                },
                {
                    "id": 7,
                    "title": "Renting guides"
                }
            ]
        },
        {
            "id": 20,
            "title": null,
            "fullUrl": "/property-advice/renting-guides/moving-out",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 4,
                    "title": "Property advice"
                },
                {
                    "id": 7,
                    "title": "Renting guides"
                }
            ]
        },
        {
            "id": 21,
            "title": null,
            "fullUrl": "/property-advice/renting-guides/tenancy-fees-act",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 4,
                    "title": "Property advice"
                },
                {
                    "id": 7,
                    "title": "Renting guides"
                }
            ]
        },
        {
            "id": 8,
            "title": "Developers",
            "fullUrl": "/developers",
            "contentType": 0,
            "menuId": 0,
            "relativeIconUrl": "contentimages/svg/b2dbae37-ea33-4909-b111-e2cc731f4fc4.svg",
            "isHidden": false,
            "ancestors": []
        },
        {
            "id": 22,
            "title": null,
            "fullUrl": "/developers/xml-feed-spec",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 8,
                    "title": "Developers"
                }
            ]
        },
        {
            "id": 23,
            "title": null,
            "fullUrl": "/developers/leads-webhook",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 8,
                    "title": "Developers"
                }
            ]
        },
        {
            "id": 24,
            "title": null,
            "fullUrl": "/developers/pixel-conversions",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 8,
                    "title": "Developers"
                }
            ]
        },
        {
            "id": 9,
            "title": "Placebuzz for Agents",
            "fullUrl": "/for-agents",
            "contentType": 0,
            "menuId": 0,
            "relativeIconUrl": "",
            "isHidden": false,
            "ancestors": []
        },
        {
            "id": 25,
            "title": null,
            "fullUrl": "/for-agents/advertise",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 9,
                    "title": "Placebuzz for Agents"
                }
            ]
        },
        {
            "id": 26,
            "title": null,
            "fullUrl": "/for-agents/terms",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 9,
                    "title": "Placebuzz for Agents"
                }
            ]
        },
        {
            "id": 32,
            "title": null,
            "fullUrl": "/for-agents/listing-sources",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 9,
                    "title": "Placebuzz for Agents"
                }
            ]
        },
        {
            "id": 10,
            "title": "Agent Portal User Guide",
            "fullUrl": "/for-agents/user-guide",
            "contentType": 0,
            "menuId": 0,
            "relativeIconUrl": "",
            "isHidden": false,
            "ancestors": [
                {
                    "id": 9,
                    "title": "Placebuzz for Agents"
                }
            ]
        },
        {
            "id": 28,
            "title": null,
            "fullUrl": "/for-agents/user-guide/accessing-partner-area",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 9,
                    "title": "Placebuzz for Agents"
                },
                {
                    "id": 10,
                    "title": "Agent Portal User Guide"
                }
            ]
        },
        {
            "id": 27,
            "title": null,
            "fullUrl": "/for-agents/user-guide/account-settings",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 9,
                    "title": "Placebuzz for Agents"
                },
                {
                    "id": 10,
                    "title": "Agent Portal User Guide"
                }
            ]
        },
        {
            "id": 29,
            "title": null,
            "fullUrl": "/for-agents/user-guide/manage-listings",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 9,
                    "title": "Placebuzz for Agents"
                },
                {
                    "id": 10,
                    "title": "Agent Portal User Guide"
                }
            ]
        },
        {
            "id": 30,
            "title": null,
            "fullUrl": "/for-agents/user-guide/lead-report",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 9,
                    "title": "Placebuzz for Agents"
                },
                {
                    "id": 10,
                    "title": "Agent Portal User Guide"
                }
            ]
        },
        {
            "id": 31,
            "title": null,
            "fullUrl": "/for-agents/user-guide/activity-report",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 9,
                    "title": "Placebuzz for Agents"
                },
                {
                    "id": 10,
                    "title": "Agent Portal User Guide"
                }
            ]
        },
        {
            "id": 11,
            "title": "Blog",
            "fullUrl": "/blog",
            "contentType": 0,
            "menuId": 2,
            "relativeIconUrl": "contentimages/svg/88501a05-eaef-4355-aac9-2999556d2ad4.svg",
            "isHidden": false,
            "ancestors": []
        },
        {
            "id": 42,
            "title": null,
            "fullUrl": "/blog/competition-time",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": true,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                }
            ]
        },
        {
            "id": 12,
            "title": "Property News",
            "fullUrl": "/blog/property-news",
            "contentType": 0,
            "menuId": 0,
            "relativeIconUrl": "/contentimages/svg/7cb623c0-336f-4900-8ffd-d7a67817d090.svg",
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                }
            ]
        },
        {
            "id": 37,
            "title": null,
            "fullUrl": "/blog/property-news/5-things-to-love-about-cornwall",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 12,
                    "title": "Property News"
                }
            ]
        },
        {
            "id": 38,
            "title": null,
            "fullUrl": "/blog/property-news/6-most-picturesque-places-to-live-in-wales",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 12,
                    "title": "Property News"
                }
            ]
        },
        {
            "id": 39,
            "title": null,
            "fullUrl": "/blog/property-news/becoming-bristol",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 12,
                    "title": "Property News"
                }
            ]
        },
        {
            "id": 41,
            "title": null,
            "fullUrl": "/blog/property-news/the-purpose-of-a-home",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 12,
                    "title": "Property News"
                }
            ]
        },
        {
            "id": 48,
            "title": null,
            "fullUrl": "/blog/property-news/5-affordable-areas-in-london",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 12,
                    "title": "Property News"
                }
            ]
        },
        {
            "id": 50,
            "title": null,
            "fullUrl": "/blog/property-news/discovering-yorkshire",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 12,
                    "title": "Property News"
                }
            ]
        },
        {
            "id": 52,
            "title": null,
            "fullUrl": "/blog/property-news/top-6-coastal-hotspots-in-the-uk",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 12,
                    "title": "Property News"
                }
            ]
        },
        {
            "id": 53,
            "title": null,
            "fullUrl": "/blog/property-news/7-attractions-of-brighton",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 12,
                    "title": "Property News"
                }
            ]
        },
        {
            "id": 57,
            "title": null,
            "fullUrl": "/blog/property-news/6-thriving-areas-in-surrey",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 12,
                    "title": "Property News"
                }
            ]
        },
        {
            "id": 62,
            "title": null,
            "fullUrl": "/blog/property-news/6-areas-of-outstanding-natural-beauty-in-the-uk",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 12,
                    "title": "Property News"
                }
            ]
        },
        {
            "id": 66,
            "title": null,
            "fullUrl": "/blog/property-news/7-attractions-in-leeds",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 12,
                    "title": "Property News"
                }
            ]
        },
        {
            "id": 13,
            "title": "Property Tips",
            "fullUrl": "/blog/property-tips",
            "contentType": 0,
            "menuId": 0,
            "relativeIconUrl": "/contentimages/svg/9b788cca-fee0-49e4-893e-147c2c439a25.svg",
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                }
            ]
        },
        {
            "id": 33,
            "title": null,
            "fullUrl": "/blog/property-tips/choosing-your-new-neighbourhood",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 13,
                    "title": "Property Tips"
                }
            ]
        },
        {
            "id": 34,
            "title": null,
            "fullUrl": "/blog/property-tips/moving-house-packing-tips",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 13,
                    "title": "Property Tips"
                }
            ]
        },
        {
            "id": 36,
            "title": null,
            "fullUrl": "/blog/property-tips/5-steps-to-creating-a-happy-home",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 13,
                    "title": "Property Tips"
                }
            ]
        },
        {
            "id": 47,
            "title": null,
            "fullUrl": "/blog/property-tips/everything-has-a-place-storage-tips",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 13,
                    "title": "Property Tips"
                }
            ]
        },
        {
            "id": 49,
            "title": null,
            "fullUrl": "/blog/property-tips/bringing-colour-into-your-home",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 13,
                    "title": "Property Tips"
                }
            ]
        },
        {
            "id": 54,
            "title": null,
            "fullUrl": "/blog/property-tips/buying-a-property-at-auction",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 13,
                    "title": "Property Tips"
                }
            ]
        },
        {
            "id": 55,
            "title": null,
            "fullUrl": "/blog/property-tips/selling-a-property-at-auction",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 13,
                    "title": "Property Tips"
                }
            ]
        },
        {
            "id": 58,
            "title": null,
            "fullUrl": "/blog/property-tips/a-guide-on-shared-accommodation",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 13,
                    "title": "Property Tips"
                }
            ]
        },
        {
            "id": 60,
            "title": null,
            "fullUrl": "/blog/property-tips/property-viewings-what-to-look-for",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 13,
                    "title": "Property Tips"
                }
            ]
        },
        {
            "id": 61,
            "title": null,
            "fullUrl": "/blog/property-tips/preparing-for-a-house-deposit",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 13,
                    "title": "Property Tips"
                }
            ]
        },
        {
            "id": 65,
            "title": null,
            "fullUrl": "/blog/property-tips/a-guide-to-renting-with-pets",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 13,
                    "title": "Property Tips"
                }
            ]
        },
        {
            "id": 14,
            "title": "Your Stories",
            "fullUrl": "/blog/your-stories",
            "contentType": 0,
            "menuId": 0,
            "relativeIconUrl": "/contentimages/svg/5ec43579-a297-4b69-b0d7-ba33e177c2de.svg",
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                }
            ]
        },
        {
            "id": 35,
            "title": null,
            "fullUrl": "/blog/your-stories/our-wandsworth-home",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 14,
                    "title": "Your Stories"
                }
            ]
        },
        {
            "id": 40,
            "title": null,
            "fullUrl": "/blog/your-stories/house-number-44",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 14,
                    "title": "Your Stories"
                }
            ]
        },
        {
            "id": 45,
            "title": null,
            "fullUrl": "/blog/your-stories/oaken-home",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 14,
                    "title": "Your Stories"
                }
            ]
        },
        {
            "id": 56,
            "title": null,
            "fullUrl": "/blog/your-stories/regent-lodge-georgian-home",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 14,
                    "title": "Your Stories"
                }
            ]
        },
        {
            "id": 15,
            "title": "Homeware ideas",
            "fullUrl": "/blog/homeware-ideas",
            "contentType": 0,
            "menuId": 0,
            "relativeIconUrl": "/contentimages/svg/f0ffac82-67f5-48f7-ae5e-e3235ce49dd8.svg",
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                }
            ]
        },
        {
            "id": 43,
            "title": null,
            "fullUrl": "/blog/homeware-ideas/the-little-house-shop",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 15,
                    "title": "Homeware ideas"
                }
            ]
        },
        {
            "id": 44,
            "title": null,
            "fullUrl": "/blog/homeware-ideas/cotswold-grey",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 15,
                    "title": "Homeware ideas"
                }
            ]
        },
        {
            "id": 46,
            "title": null,
            "fullUrl": "/blog/homeware-ideas/silver-mushroom",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 15,
                    "title": "Homeware ideas"
                }
            ]
        },
        {
            "id": 51,
            "title": null,
            "fullUrl": "/blog/homeware-ideas/dill",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 15,
                    "title": "Homeware ideas"
                }
            ]
        },
        {
            "id": 59,
            "title": null,
            "fullUrl": "/blog/homeware-ideas/betty-and-mojo",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 15,
                    "title": "Homeware ideas"
                }
            ]
        },
        {
            "id": 63,
            "title": null,
            "fullUrl": "/blog/homeware-ideas/home-of-boho",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 15,
                    "title": "Homeware ideas"
                }
            ]
        },
        {
            "id": 64,
            "title": null,
            "fullUrl": "/blog/homeware-ideas/surrey-style-interiors",
            "contentType": 1,
            "menuId": 0,
            "relativeIconUrl": null,
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                },
                {
                    "id": 15,
                    "title": "Homeware ideas"
                }
            ]
        },
        {
            "id": 16,
            "title": "Home Styling Tips",
            "fullUrl": "/blog/home-styling-tips",
            "contentType": 0,
            "menuId": 0,
            "relativeIconUrl": "/contentimages/svg/bdbef091-0216-4e08-ae12-bb88ceb12bfe.svg",
            "isHidden": false,
            "ancestors": [
                {
                    "id": 11,
                    "title": "Blog"
                }
            ]
        }
    ],
    "applicationSettings": [
        {
            "category": "Mortgages",
            "kvps": [
                {
                    "key": "Enable_Quotes",
                    "value": "False",
                    "valueType": "boolean"
                }
            ]
        },
        {
            "category": "Application Settings",
            "kvps": [
                {
                    "key": "HomepageHeroUrl",
                    "value": "\"https://cdn.placebuzz.com/location/hero_houses_2.jpg\"",
                    "valueType": "string"
                },
                {
                    "key": "HomepageHeroCaption",
                    "value": "\"Colourful houses, Liverpool\"",
                    "valueType": "string"
                },
                {
                    "key": "GeocodeCountryCode",
                    "value": "\"UK\"",
                    "valueType": "string"
                },
                {
                    "key": "ShowCookieDisclaimer",
                    "value": "False",
                    "valueType": "boolean"
                }
            ]
        },
        {
            "category": "Feature Switches",
            "kvps": [
                {
                    "key": "Valuations",
                    "value": "True",
                    "valueType": "boolean"
                },
                {
                    "key": "AreaGuides",
                    "value": "True",
                    "valueType": "boolean"
                }
            ]
        }
    ],
    "localityRatingScores": [
        {
            "id": 0,
            "lookUpOrder": 0,
            "name": "Not Set",
            "score": 0
        },
        {
            "id": 1,
            "lookUpOrder": 1,
            "name": "Poor",
            "score": 1
        },
        {
            "id": 2,
            "lookUpOrder": 2,
            "name": "Average",
            "score": 2
        },
        {
            "id": 3,
            "lookUpOrder": 3,
            "name": "Fabulous",
            "score": 3
        },
        {
            "id": 4,
            "lookUpOrder": 4,
            "name": "Exceptional",
            "score": 4
        },
        {
            "id": 5,
            "lookUpOrder": 5,
            "name": "Superb",
            "score": 5
        }
    ],
    "localityRatingQuestions": [
        {
            "id": 1,
            "name": "At a glance",
            "lookUpOrder": 1,
            "hasRatingScore": false,
            "iconLetter": "",
            "isVisible": false
        },
        {
            "id": 2,
            "name": "Community",
            "lookUpOrder": 2,
            "hasRatingScore": true,
            "iconLetter": "",
            "isVisible": true
        },
        {
            "id": 3,
            "name": "Safety",
            "lookUpOrder": 3,
            "hasRatingScore": true,
            "iconLetter": "",
            "isVisible": true
        },
        {
            "id": 4,
            "name": "Prices",
            "lookUpOrder": 4,
            "hasRatingScore": true,
            "iconLetter": "",
            "isVisible": false
        },
        {
            "id": 5,
            "name": "Parking",
            "lookUpOrder": 5,
            "hasRatingScore": true,
            "iconLetter": "",
            "isVisible": true
        },
        {
            "id": 6,
            "name": "Nightlife",
            "lookUpOrder": 6,
            "hasRatingScore": true,
            "iconLetter": "",
            "isVisible": true
        },
        {
            "id": 7,
            "name": "Family Friendly",
            "lookUpOrder": 7,
            "hasRatingScore": true,
            "iconLetter": "",
            "isVisible": true
        },
        {
            "id": 8,
            "name": "Pet Friendly",
            "lookUpOrder": 8,
            "hasRatingScore": true,
            "iconLetter": "",
            "isVisible": true
        },
        {
            "id": 9,
            "name": "Diversity",
            "lookUpOrder": 9,
            "hasRatingScore": true,
            "iconLetter": "",
            "isVisible": false
        },
        {
            "id": 10,
            "name": "Transport",
            "lookUpOrder": 10,
            "hasRatingScore": true,
            "iconLetter": "",
            "isVisible": true
        }
    ]
}
import { GqlResponseBase } from "../graphql.module/model";

export class ContentPageAncestor {
    id: number;
    title: string;
}

export class ContentPageItem {
    id: number;
    title: string;
    fullUrl: string;
    contentType: number;
    ancestors: Array<ContentPageAncestor>;
    // iconUrl: string;
    relativeIconUrl: string;
    menuId: number;
    isHidden: boolean;
}

export const ContentTypes = {
    Section: 0,
    Article: 1
}

export interface ApplicationSettingKVP {
    key: string,
    value: string,
    valueType: string
}

export interface TranslationSetting{
  key:string,
  value:string
}

export interface ApplicationSetting {
    category: string,
    kvps: Array<ApplicationSettingKVP>
}

export interface LocalityRatingScore {
    id: number;
    name: string;
    score: number;
    lookUpOrder: number;
}

export interface LocalityRatingQuestion {
    id: number;
    name: string;
    lookUpOrder: number;
    hasRatingScore: boolean;
    iconLetter: string;
    isVisible: boolean;
}

export class ApplicationResources {
    heroImageUrl: string;
    heroCaption: string;
    followersCount: number;
    followersCountFormatted: string;
    contentSectionsCache: Array<ContentPageItem>;
    contentArticlesCache: Array<ContentPageItem>;
    contentCache: Array<ContentPageItem>;
    menu: Menu;
    language: string;
    applicationSettings: Array<ApplicationSetting>;
    localityRatingScores: Array<LocalityRatingScore>;
    localityRatingQuestions: Array<LocalityRatingQuestion>;
    i18N:Array<TranslationSetting>;
}


export class ApplicationResourcesResponse extends GqlResponseBase {
    applicationResources: ApplicationResources;
}

export class Menu {
    items: Array<MenuItem>;
}

export interface MenuItem {
    text: string;
    link?: string;
    actionTrackingLabel: string;
    subItems?: Array<SubMenuItem>
    secondaryNavBarAssociationId?: string;
    subMenuId?: string;
    isCurrentSelection?: boolean;
    badgeCountPropertyName?: string;
    badgeCount?: number;
    performActionButtonId?: string;
    isMobileMain?: boolean;
}

export interface SubMenuItem {
    type: number;
    hideOnMobile?: boolean,
    fontIconClass?: string,
    iconClasses?: string,
    iconUrl?: string,
    caption?: string,
    link?: string,
    actionTrackingLabel?: string,
    clickTrackingType?: string
}

export const SubMenuItemTypes = {
    Link: 0,
    Separator: 1
}

export const MenuTypes = {
    No_Menu: 0,
    Menu_Bar: 1,
    More_Menu: 2,
    Buy_Menu: 3,
    Rent_Menu: 4
}

export class AffiliateAds {
  items: Array<AffiliateAd>;
}

export interface AffiliateAd {
  enabled: boolean;
  name:string;
  image: string;
  position?: number;
  caption: string;
  href:string;
  trackingSuffix:string;
  affiliate:string;
}

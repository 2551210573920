import { Injectable } from '@angular/core';
import { ApplicationResources, ApplicationSetting, ApplicationSettingKVP, TranslationSetting, AffiliateAds, AffiliateAd, SubMenuItemTypes, ContentPageItem, Menu, MenuItem, MenuTypes, ContentTypes } from './model';
import { localApplicationResources } from '../initialization.module/application.resources';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class ApplicationResourcesService {
    private applicationResources: ApplicationResources;
    private flattenedSettings: Array<ApplicationSettingKVP>;
    private translations: Array<TranslationSetting>;

    constructor() {
        let appResources = localApplicationResources as unknown as ApplicationResources;
        appResources.contentArticlesCache = appResources.contentCache.filter(p => p.contentType === ContentTypes.Article);
        appResources.contentSectionsCache = appResources.contentCache.filter(p => p.contentType === ContentTypes.Section);
        
        appResources.menu = this.UpdateMenu(appResources.contentSectionsCache);
        this.applicationResources = appResources;

        this.translations = this.applicationResources.i18N;

        this.flattenedSettings = [];

        for (var categorySettings of this.GetApplicationResources().applicationSettings.map(s => s.kvps)) {
            this.flattenedSettings = [...this.flattenedSettings, ...categorySettings]
        }
    }

    GetApplicationResources(): ApplicationResources {

      
        return this.applicationResources;
    }


    private UpdateMenu(contentSectionItems: ContentPageItem[]): Menu {
      var menu = new Menu();

      var items: Array<MenuItem> = [{
          // BUY MENU BUTTON
          text: "Buy",
          actionTrackingLabel: "NavBar-Buy",
          link: "/property-for-sale",
      }, {
          // RENT MENU BUTTON
          text: "Rent",
          actionTrackingLabel: "NavBar-Rent",
          secondaryNavBarAssociationId: "rentSecondaryNavBar",
          link: "/property-for-rent"
      }, {
          // AREA GUIDES MENU BUTTON
          text: "Area Guides",
          actionTrackingLabel: "NavBar-Area guides",
          link: "/area-guides"
      }, {
          // SEARCHES MENU BUTTON
          text: "Searches",
          actionTrackingLabel: "NavBar-My Searches",
          link: "/searches",
          badgeCountPropertyName: "searchesBadgeCount"
      }, {
          // FAVOURITES MENU BUTTON
          text: "Favourites",
          performActionButtonId: "FAVOURITES",
          actionTrackingLabel: "NavBar-My Favourites",
          badgeCountPropertyName: "favouritesBadgeCount",
          isMobileMain: true
      }, {
          // ENQUIRIES MENU BUTTON
          text: "Enquiries",
          performActionButtonId: "ENQUIRIES",
          actionTrackingLabel: "NavBar-My Enquiries",
          badgeCountPropertyName: "enquiriesBadgeCount",
          isMobileMain: true
      }];


      if (contentSectionItems) {
          let moreMenu: MenuItem;
          if (contentSectionItems.find(i => i.menuId === MenuTypes.More_Menu)) {
              moreMenu = {
                  text: "More",
                  actionTrackingLabel: "NavBar-More",
                  secondaryNavBarAssociationId: "moreSecondaryNavBar",
                  subItems: []
              };
          }

          for (let contentSection of contentSectionItems.filter(s => !s.isHidden)) {
              if (contentSection.menuId === MenuTypes.No_Menu) { continue; }

              var menuToAddTo: MenuItem;
              var trackingPrefix: string;

              switch (contentSection.menuId) {
                  case MenuTypes.Menu_Bar:
                      items.push({
                          text: contentSection.title,
                          actionTrackingLabel: "NavBar-" + contentSection.title,
                          link: contentSection.fullUrl,
                      });
                      continue;
                  case MenuTypes.More_Menu:
                      trackingPrefix = "NavBar-More-";
                      menuToAddTo = moreMenu;
                      break;
                  case MenuTypes.Buy_Menu:
                      trackingPrefix = "NavBar-Buy-";
                      menuToAddTo = items.find(m => m.secondaryNavBarAssociationId === "buySecondaryNavBar");
                      break;
                  case MenuTypes.Rent_Menu:
                      trackingPrefix = "NavBar-Rent-";
                      menuToAddTo = items.find(m => m.secondaryNavBarAssociationId === "rentSecondaryNavBar");
                      break;
              }

              menuToAddTo.subItems.push({
                  type: SubMenuItemTypes.Separator,
                  hideOnMobile: menuToAddTo.subItems.length === 0
              });
              menuToAddTo.subItems.push({
                  type: SubMenuItemTypes.Link,
                  iconUrl: environment.mediaServerUrl + "/" + contentSection.relativeIconUrl,
                  link: contentSection.fullUrl,
                  caption: contentSection.title,
                  actionTrackingLabel: trackingPrefix + contentSection.title
              });
          }

          if (moreMenu && moreMenu.subItems.length > 0) {
              items.push(moreMenu);
          }
      }

      for (var item of items.filter(i => !!i.subItems)) {
          item.subItems.push({
              type: SubMenuItemTypes.Separator,
              hideOnMobile: true
          });
      }

      menu.items = items;
      return menu;
  }

    SetApplicationResources(resources: ApplicationResources): void {
        this.applicationResources = resources;

        this.translations = this.applicationResources.i18N;

        this.flattenedSettings = [];

        for (var categorySettings of this.GetApplicationResources().applicationSettings.map(s => s.kvps)) {
            this.flattenedSettings = [...this.flattenedSettings, ...categorySettings]
        }
    }

    GetApplicationSetting(key: string, defaultValue: any): any {
        let entry = this.flattenedSettings.find(s => s.key === key);
        if (!entry) { return defaultValue; }

        if (entry.valueType.endsWith("array")) {
            let result = this.ParseArray(entry.value, entry.valueType);
            if (result && result.length > 0) {
                return result;
            }
        }
        else {
            return this.ParseScalar(entry.value, entry.valueType);
        }


        return defaultValue;
    }

    GetAffiliateAd(name:string):AffiliateAd{

      var affiliateAd:AffiliateAd;

      try{
        affiliateAd = this.GetApplicationSetting(name, "");

        if (!affiliateAd || !affiliateAd.enabled || !affiliateAd.caption || !affiliateAd.href || !affiliateAd.image || !affiliateAd.trackingSuffix){
          return null;
        }

        if (!affiliateAd.enabled==true){
          return null;
        }

      }
      catch{
          return null;
      }
    return affiliateAd;
  }



    GetTranslation(key:string):any{
      if (!this.translations){
        return "";
      }
      let translation = this.translations.find(s=>s.key == key);
      if (!translation){
        return "";
      }
      return translation;
    }

    private ParseArray(entryValue: any, entryType: string) {
        let value = entryValue.slice(1, -1).replace("[", "").replace("]", "");
        let split = value.split(",");
        let result = [];
        for (let v of split) {
            result.push(this.ParseScalar(v, entryType.replace(" array", "")));
        }
        return result;
    }

    private ParseScalar(entryValue: any, entryType: string) {
        switch (entryType) {
            case "boolean":
                return entryValue.toLowerCase() === "true";
            case "number":
                return parseInt(entryValue);
            case "string":
                return entryValue.slice(1, -1);
            case "object":
                return JSON.parse(entryValue);
        }
    }
}
